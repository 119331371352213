




























import Button from '@/components/ui/Button/Button.vue'

export default {
  components: {
    'ui-button': Button
  },
  props: {
    title: {
      type: String,
      default: 'Dialog title'
    },
    mainClass: {
      type: String,
      default: 'mt-2 p-4'
    }
  },
  data () {
    return {
      isDown: false,
      offset: [0, 0],
      mousePosition: { x: null, y: null }
    }
  },
  methods: {
    handleMouseDown (event) {
      if (event.target.tagName === 'HEADER') {
        this.isDown = true
        this.offset = [
          this.$refs.draggableModal.offsetLeft - event.clientX,
          this.$refs.draggableModal.offsetTop - event.clientY
        ]
      }
    },
    handleMouseUp () {
      this.isDown = false
    },
    handleMouseMove (event) {
      event.preventDefault()
      if (this.isDown) {
        this.mousePosition = {
          x: event.clientX,
          y: event.clientY
        }
        this.$refs.draggableModal.style.left = (this.mousePosition.x + this.offset[0]) + 'px'
        this.$refs.draggableModal.style.top = (this.mousePosition.y + this.offset[1]) + 'px'
        this.$refs.draggableModal.style.bottom = 'auto'
      }
    }
  },
  mounted () {
    this.$refs.draggableModal.addEventListener('mousedown', (event) => this.handleMouseDown(event), true)
    document.addEventListener('mouseup', () => this.handleMouseUp(), true)
    document.addEventListener('mousemove', (event) => this.handleMouseMove(event), true)
  },
  beforeDestroy () {
    this.$refs.draggableModal.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('mouseup', this.handleMouseUp)
    document.removeEventListener('mousemove', this.handleMouseMove)
  }
}
